<template>
  <svg
    viewBox="0 0 19 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.9187 3H3.1687V9.25C3.1687 12.6961 5.97261 15.5 9.4187 15.5C12.8648 15.5 15.6687 12.6961 15.6687 9.25V3H16.9187C17.0845 3 17.2434 2.93415 17.3606 2.81694C17.4779 2.69973 17.5437 2.54076 17.5437 2.375V1.125C17.5437 0.95924 17.4779 0.800269 17.3606 0.683058C17.2434 0.565848 17.0845 0.5 16.9187 0.5H11.2937C11.1279 0.5 10.969 0.565848 10.8518 0.683058C10.7345 0.800269 10.6687 0.95924 10.6687 1.125V2.375C10.6687 2.54076 10.7345 2.69973 10.8518 2.81694C10.969 2.93415 11.1279 3 11.2937 3H12.5437V9.25C12.5437 10.0788 12.2145 10.8737 11.6284 11.4597C11.0424 12.0458 10.2475 12.375 9.4187 12.375C8.5899 12.375 7.79504 12.0458 7.20899 11.4597C6.62294 10.8737 6.2937 10.0788 6.2937 9.25V3H7.5437C7.70946 3 7.86843 2.93415 7.98564 2.81694C8.10285 2.69973 8.1687 2.54076 8.1687 2.375V1.125C8.1687 0.95924 8.10285 0.800269 7.98564 0.683058C7.86843 0.565848 7.70946 0.5 7.5437 0.5H1.9187C1.75294 0.5 1.59397 0.565848 1.47676 0.683058C1.35955 0.800269 1.2937 0.95924 1.2937 1.125V2.375C1.2937 2.54076 1.35955 2.69973 1.47676 2.81694C1.59397 2.93415 1.75294 3 1.9187 3ZM17.5437 18H1.2937C1.12794 18 0.96897 18.0658 0.851759 18.1831C0.734549 18.3003 0.668701 18.4592 0.668701 18.625L0.668701 19.875C0.668701 20.0408 0.734549 20.1997 0.851759 20.3169C0.96897 20.4342 1.12794 20.5 1.2937 20.5H17.5437C17.7095 20.5 17.8684 20.4342 17.9856 20.3169C18.1029 20.1997 18.1687 20.0408 18.1687 19.875V18.625C18.1687 18.4592 18.1029 18.3003 17.9856 18.1831C17.8684 18.0658 17.7095 18 17.5437 18Z"
      fill="currentColor"
    />
  </svg>
</template>
